<script>
import HorizontalTopbar from "@/components/horizontal-topbar"
import HorizontalNav from "@/components/horizontal-navbar"
import { layoutComputed } from "@/state/helpers"
import { http } from "@/helpers/easyindustriaapi/config"
import Swal from "sweetalert2"
import Detail from "@/views/pages/atendimentos/detail"

export default {
  components: { HorizontalTopbar, HorizontalNav, Detail },
  data() {
    return {
      vet_convenios:[],
      vet_abordagens:[],
      vet_responsaveis:[],
      vet_tramentos:[],
      vet_pacientes:[],
      vet_profissionais:[],
      currentAtendimento:{},
      currentProfessional: {},
      professionals: [],
      inserindo: false,
      editando: false,
      titleBody: "Lista de Atendimentos",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Atendimentos",
          href: "/atendimentos",
          active: true,
        },
      ],
      fields: [
        {'label': 'Código', 'key': 'id', 'sortable': true, 'class': 'text-right', thClass: 'text-center'},
        {'label': 'De', 'key': 'start_time', 'sortable': true, 'class': 'text-center', thClass: 'text-center'},
        {'label': 'Até', 'key': 'end_time', 'sortable': true, 'class': 'text-center', thClass: 'text-center'},
        {'label': 'Agendamentos', 'key': 'agendas', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Paciente', 'key': 'pacient', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Responsável', 'key': 'responsible', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Local', 'key': 'local', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Tratamento', 'key': 'treatment', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Abordagem', 'key': 'approach', 'sortable': true, 'class': 'text-left', thClass: 'text-center'},
        {'label': 'Ações', 'key': 'actions', 'sortable': true, 'class': 'text-center', thClass: 'text-center'},
      ],
      atendimentos:[],
    };
  },
  computed: {
    ...layoutComputed,
    isHide() {
      return !this.inserindo && !this.editando
    },
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"))
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"))
  },
  mounted() {
    this.getData()
  },
  methods: {
    getTable() {
      Swal.fire({
        title: "Aguarde",
        text: "Carregando tabelas...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      });

      const promisse = http.get("/atendimento/tabelas?empresa_id=" + this.currentEmpresa.id)
      return promisse.then((response) => {
        if (response.status === 200) {
          this.vet_profissionais = response.data.professionals
          this.vet_pacientes     = response.data.pacients
          this.vet_tramentos     = response.data.specialtys
          this.vet_abordagens    = response.data.approachs
          this.vet_convenios     = response.data.agreements
          Swal.close()
          return true
        }
      }).catch((error) => {
        console.log(error.response.status)

        if (error.response.status != 403) {
          Swal.close()
          Swal.fire({
            icon: "warning",
            title: "Acesso negado",
            text: `Erro: ${error.response.data.message}\n.`,
          })
        }
      }).finally(() => {
      })
    },
    getData() {
      Swal.close()
      Swal.fire({
        title: "Aguarde",
        text: "Carregando Atendimentos...",
        allowOutsideClick: false,
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })

      const promisse = http.get("/atendimento/profissional?empresa_id=" + this.currentEmpresa.id)
      return promisse.then((response) => {
        if (response.status === 200) {
          this.atendimentos = response.data.data
          Swal.close()
          return this.atendimentos
        }
      }).catch((error) => {
        console.log(error)
        this.makeToast("danger", error.response.data)
      }).finally(() => {
        Swal.close()
      })
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      })
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle
    },
    back() {
      this.inserindo = false
      this.editando = false
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block"
        document.getElementById("status").style.display = "block"
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none"
          document.getElementById("status").style.display = "none"
        }, 2500)
      }
    },
    getDayName(dayNumber) {
      const days = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
      return days[dayNumber - 1] // Ajuste o índice se precisar (0-based ou 1-based)
    },
    setAtendimento(atendimento) {
      this.currentAtendimento = atendimento
    },
  },
};
</script>

<template>
  <div>
    <!-- Modal Detail component -->
    <b-modal id="modal-detail" hide-footer title="Detalhes do Atendimento" size="lg">
      <Detail :atendimento="currentAtendimento" />
    </b-modal>
    <!-- Begin page -->
    <div id="layout-wrapper">
      <HorizontalTopbar :type="topbar" :width="layoutWidth"/>
      <HorizontalNav />
      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="">
                      <a v-if="!isHide" @click="back()" class="btn btn-secondary mb-2"><i class="mdi mdi-plus mr-2"></i> Voltar </a>
                    </div>
                    <div class="table-responsive mt-3">
                      <b-table
                        :items="atendimentos"
                        :fields="fields"
                      >
                        <template #cell(agendas)="row">
                          <div role="tablist" v-for="(agenda, index) in row.item.agendas" :key="index">
                            <b-card no-body class="mb-1 shadow-none">
                              <b-card-header header-tag="header" role="tab">
                                <h6 class="m-0">
                                  <b-button 
                                    v-b-toggle="'accordion-'+index+'-'+row.index" 
                                    variant="default" 
                                    size="sm"
                                  >+</b-button> {{ getDayName(agenda.day_of_week) }}
                                </h6>
                              </b-card-header>
                              <b-collapse :id="'accordion-'+index+'-'+row.index" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <p>{{ agenda.title }}</p>
                                    <p>{{ getDayName(agenda.day_of_week) }}</p>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                        </template>
                        <template #cell(actions)="row" class="mb-2">
                          <!-- Dropdown menu buttons actions align to the dropleft -->
                          <b-dropdown class="m-2" variant="primary" size="sm" dropleft>
                            <template #button-content>
                              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                            </template>
                            <b-dropdown-item
                             v-b-modal.modal-detail
                             @click="setAtendimento(row.item)"
                            >
                              <i class="bx ri-file-search-line"></i> Visualizar
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-table>
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>